<template>
  <div style="height:100%">
    <portal to="app-header">
      <span>{{ $t('manpower.title') }}</span>
      <v-btn icon small class="ml-4 mb-1">
        <v-icon
          v-text="'$info'"
        ></v-icon>
      </v-btn>
      <!-- <v-btn icon small class="ml-2 mb-1">
        <v-icon
          v-text="'$settings'"
        ></v-icon>
      </v-btn> -->
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            class="ml-2 mb-1"
            @click="refreshManpowers"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        {{ $t('manpower.lastRefreshed') }} <strong>{{ lastRefreshedAt }}</strong>
      </v-tooltip>
    </portal>
    <manpower-log-loading v-if="loading" />
    <template v-else>
      <manpower-toolbar />
      <manpower-drawer />
      <manpower-list />
    </template>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import ManpowerLogLoading from './ManpowerLogLoading.vue';
import ManpowerToolbar from '../components/ManpowerToolbar.vue';
import ManpowerDrawer from '../components/ManpowerDrawer.vue';
import ManpowerList from '../components/ManpowerList.vue';

export default {
  name: 'ManpowerLog',
  components: {
    ManpowerLogLoading,
    ManpowerToolbar,
    ManpowerDrawer,
    ManpowerList,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('manpowerLog', ['lastRefreshedAt']),
    ...mapState('webApp', ['config', 'storageLocation']),
  },
  async created() {
    this.loading = true;
    const config = localStorage.getItem(this.storageLocation.manpower);
    if (config) {
      this.setConfig(JSON.parse(config));
    } else {
      this.resetConfig();
    }
    await this.getAppSchema();
    this.setExtendedHeader(true);
    this.loading = false;
  },
  methods: {
    ...mapMutations('webApp', ['setConfig', 'resetConfig']),
    ...mapMutations('helper', ['setExtendedHeader']),
    ...mapActions('webApp', ['getAppSchema']),
    ...mapActions('manpowerLog', [
      'fetchManpowerList',
    ]),
    refreshManpowers() {
      this.fetchManpowerList();
    },
  },
  watch: {
    config: {
      deep: true,
      handler(val) {
        localStorage.setItem(this.storageLocation.manpower, JSON.stringify(val));
      },
    },
  },
};
</script>
