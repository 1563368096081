<template>
  <v-container fluid style="height:100%">
    <v-row>
      <v-col cols="4">
        <v-select
          :items="hourList"
          item-text="key"
          label="Hour"
          outlined
          dense
          return-object
          v-model="hour"
          :disabled="loading || saving"
          hide-details="auto"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          type="text"
          outlined
          dense
          :disabled="loading || saving"
          v-model.number="count"
          label="Manpower count"
          autocomplete="off"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn
          block
          :disabled="loading || !hour"
          :loading="saving"
          color="primary"
          class="text-none"
          @click="saveManpower"
        >
          Submit
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="error">
      <manpower-error />
    </template>
    <template v-else>
      <v-data-table
        :items="manpower"
        :loading="loading"
        :headers="headers"
        disable-pagination
        hide-default-footer
      >
        <template #loading> <manpower-loading /> </template>
        <template #no-data> <manpower-no-records /> </template>
      </v-data-table>
    </template>
  </v-container>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import ManpowerLoading from './ManpowerLoading.vue';
import ManpowerError from './ManpowerError.vue';
import ManpowerNoRecords from './ManpowerNoRecords.vue';

export default {
  name: 'ManpowerList',
  components: {
    ManpowerLoading,
    ManpowerError,
    ManpowerNoRecords,
  },
  data() {
    return {
      hour: null,
      count: 0,
      saving: false,
      headers: [
        { text: 'Hour', value: 'hourrange' },
        { text: 'Manpower Count', value: 'manpower' },
      ],
    };
  },
  computed: {
    ...mapState('manpowerLog', [
      'loading',
      'error',
      'toggleSelection',
      'cells',
    ]),
    ...mapGetters('webApp', ['filters']),
    ...mapGetters('manpowerLog', ['manpower', 'hourList', 'cellList']),
    cell() {
      return this.filters?.cellname?.value;
    },
  },
  methods: {
    ...mapActions('manpowerLog', ['fetchManpowerList', 'createManpower']),
    ...mapActions('element', ['updateRecordById']),
    async saveManpower() {
      const cellname = JSON.parse(localStorage.getItem('manpowerConfig')).filters.cellname.value;
      let targetManpower;
      if (this.cells) {
        targetManpower = this.cells.find((c) => c.cellname === cellname);
      }
      this.saving = true;
      if (this.manpower.length === 0) {
        const index = this.hourList.findIndex((h) => h.key === this.hour.key);
        await Promise.all(this.hourList.map(async (h, i) => {
          const payload = {
            sortindex: i,
            hourrange: h.key,
            cellname: this.cell,
            targetmanpower: targetManpower.targetmanpower,
            timestamp: h.timestamp,
            manpower: i < index ? 0 : this.count,
          };
          await this.createManpower(payload);
        }));
      } else {
        const index = this.manpower.findIndex((h) => h.hourrange === this.hour.key);
        const list = this.manpower.filter((_, i) => i >= index);
        await Promise.all(list.map(async (h) => {
          const payload = {
            manpower: this.count,
            targetmanpower: targetManpower.targetmanpower,
            assetid: 0,
          };
          await this.updateRecordById({
            elementName: 'manpower',
            // eslint-disable-next-line
            id: h._id,
            payload,
          });
        }));
      }
      await this.fetchManpowerList();
      this.saving = false;
    },
  },
  created() {
    this.fetchManpowerList();
  },
  watch: {
    hourList(val) {
      if (val && val.length) {
        [this.hour] = this.hourList;
      }
    },
  },
};
</script>
