<template>
  <portal to="app-extension">
    <v-toolbar
      flat
      dense
      :color="$vuetify.theme.dark ? '#121212': ''"
    >
      <v-spacer></v-spacer>
      <div class="mt-1">
        <span class="title" v-if="$vuetify.breakpoint.smAndUp">
          {{ cell }} | {{ shift }} - {{ date }}
        </span>
      </div>
      <v-btn
        icon
        small
        outlined
        class="ml-2"
        @click="toggleDrawer(true)"
      >
        <v-icon small v-if="!drawer">mdi-filter-variant</v-icon>
        <v-icon small v-else>mdi-check</v-icon>
      </v-btn>
    </v-toolbar>
  </portal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'ManpowerToolbar',
  data() {
    return {
      edit: false,
    };
  },
  computed: {
    ...mapState('manpowerLog', [
      'drawer',
      'manpowerList',
    ]),
    ...mapGetters('webApp', ['filters', 'filteredRecords']),
    ...mapGetters('helper', ['locale']),
    cell() {
      let cell = '';
      if (this.filters && this.filters.cellname) {
        cell = this.filters.cellname.value;
      }
      return cell;
    },
    shift() {
      let shift = '';
      if (this.filters && this.filters.shiftName) {
        shift = this.filters.shiftName.value;
      }
      return shift;
    },
    date() {
      return this.filters && this.filters.date
        ? formatDate(
          new Date(this.filters.date.value),
          'PP',
          { locale: this.locale },
        ) : '';
    },
  },
  methods: {
    ...mapMutations('manpowerLog', ['toggleDrawer']),
  },
};
</script>
